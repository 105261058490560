.price_card_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width:1200px){
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    @media (max-width:860px){
        grid-template-columns: 1fr;
        place-items: center;
    }
    
}
.new_pricing{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    & p{
        margin: 0;
        padding: 0;
    }
    & h{
        margin: 0;
        padding: 0;
    }
}

.new_pricing_main{
    width: 100vw;
    max-width: 1440px;
    position: relative;
    margin: 0 auto;
    margin-top: 150px;
    background: #F3FBFB;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 100px;

    @media (max-width:400px){
        overflow-x: hidden;
    }
    
    .pricing_main_wrapper{
        width: 77%; 
        margin: 0 auto;
        margin-bottom: 80px;
       
        .pricing_content_container{
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 106px;
            position: relative;
            z-index: 2;

            @media (max-width:700px){
                width: 100%;
            }
            .pricing_content{
                width: 100%;
                
                .pricing_text{
                    color: var(--flick-secondary-5, #151F32);
                    text-align: center;
                    font-family: Circular Std;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 79px; /* 123.438% */

                    @media (max-width:860px){
                        font-size: 2.5rem;
                    }
                    @media (max-width:700px){
                        font-size: 2rem;
                        text-align:start;
                        line-height: 50px;
                    }
                }
                .pricing_title{
                    color: var(--Flick-Subtext, #8C8F97);
                    text-align: center;
                    font-family: Circular Std;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 140%; /* 22.4px */
                    letter-spacing: 0.2px;
                }
            }
            .Pricing_btn{
                margin: 0 auto;
                border-radius: 60px;
                background: #FFF;
                width: fit-content;
                padding: 7px;
                display: flex;
                align-items: center;
                margin-top: 80px;
                .pricing_btn{
                    display: flex;
                    width: 127px;
                    padding: 10px 14px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 60px;
                    cursor: pointer;
    
                    &.active{
                        border-radius: 60px;
                        background: var(--flick-primary-5, #2EBDB6);
                    }
                }
                
            }
        }

        .pricing_data_plan{
            margin-top: 128px;

            .pricing_data_text{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 32px;

                @media (max-width:700px){
                    flex-direction: column;
                }

                .price_data_top{
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    .price_data_h{
                        color: var(--flick-secondary-5, #151F32);
                        font-family: Circular Std;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: normal;
                    }
                    .price_data_p{
                        color: var(--Flick-Subtext, #8C8F97);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: 30px; 
                        letter-spacing: 0.2px;
                    }
                }

                .pricing_data_select{
                    border-radius: 100px;
                    background: #FFF;
                    display: flex;
                    gap: 8px;
                    padding: 10px;


                    .pricing_select{
                        border: none;
                        outline: none;
                    }
                }
            }
        }

    }
}
.pricing_rainbow{
    position: absolute;
    right: 0;
    top: 153px;
    @media (max-width:500px){
        width: 50%;
    }
}

.pricing_card{
    width: 354px;
    padding: 40px 32px 39px 32px;
    border-radius: 8px;
    background: #FFF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width:1200px){
        height: 663px;
    }
    @media (max-width:500px){
        width: 100%;
        height: 700px;

    }
    @media (max-width:400px){
        &.data_price_card{
            height: fit-content;
        }
        
    }

}
.price_card_wrapper{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    .text_pay{
        display: block;
        color: #000;
        text-align: center;
        font-family: Circular Std;
        font-size: 23px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px; 
        margin-bottom: 16px;
    }
    .text_api_call{
        color: #000;
        font-family: Circular Std;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 78px; /* 195% */
        &.font-inc{
            
            @media (max-width:400px){
                font-size: 35px;
            }
        }
        @media (max-width:500px){
            font-size: 2rem;
        }
        @media (max-width:400px){
           
        }
        
    }
    .naira_symbol{
        // text-decoration: line-through;
    }
    .text_api{
        color: var(--Subtext, #8C8F97);
        text-align: center;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.5px; /* 162.5% */
        letter-spacing: 0.325px;
        margin-right:4px;
    }

    .plan_options{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        .input_radio{
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}

.card_details{
    flex: 1;
}

.radio{
width: 15px;
height: 15px;
}
.plan_btn{
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--Flick-Stroke, #EAECF0);
    background: #FFF;
    padding: 14px;
    margin-top: 95px;
}
.user_price{
    color: var(--Flick-Subtext, #8C8F97);
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    margin-bottom: 24px;
    
    & span{
        color: var(--flick-primary-6, #259792);
    }
}


.plus{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.plus_item{
    display: flex;
    gap: 12px;
    align-items: center;
    .plus_text{
        color: var(--Flick-Subtext, #8C8F97);
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 24px; /* 150% */
    }
}
.first_btn{
   position: absolute;
   bottom: 39px;
   width: 78%;
}
.priceImage{
    margin-top: 100px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.price_img_title{
    color: #FFF;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
}
.price_img_desc{
    color: #FFF;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    margin-bottom: 24px;
}
.price_img_sec{
    color: rgba(247, 253, 252, 0.90);
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 142.5%;
}
.sec{
   background: #259792;
   padding-bottom: 26px;
   min-height:663px ;
 
}



// capped

.capped{
    color: #8C8F97;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    &.sel{    
        color: #151F32;
    }
}

.payment_btn{
    margin-top: 95px;
}

.plan_option{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
    .input_radio{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }
}

.plan_option2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: space-between;
    .input_radio{
        display: flex;
        gap: 12px;
        align-items: center;
       
    }
   

   
}




input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #2EBDB6;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}