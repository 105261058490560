.role-application{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    
    .form-role-main{
        margin-top: 150px;
        width: 77%;
        margin-left: auto;
        margin-right: auto;
    }
}

