.faqs{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EAF8F8;
    
    @media (max-width: 991px){
        margin-top: 1.5rem;
    }
   
    & .faq-container{
        width: 77%;
        margin-top:92px;
        
        .faq-container-title{
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
        .faqs-title{
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 51px;
            text-align: center;
            color: #111928;
            margin-bottom: 64px;
            @media (max-width: 361px){
                font-size: 2rem;
            }
        }

        .faq-central-div{
            display: flex;
            justify-content: space-between;
            
        }
        .faqs-div-content{
            margin-bottom: 44px;

            & h1{
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: #111928;
                margin-bottom: 16px;
            }
            
            & p{
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.2px;
                color: #4A5873;
                @media (max-width: 361px){
                    font-size: 1rem;
                }
            }
        }
    }


}