$md-layout:'only screen and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)'; 


.about{
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
    margin:0 auto;
    
}
.hero-about{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 228px;
    @media (max-width:610px){
        margin-top: 150px;
    }
    & .hero-about-container{
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 128px;
        @media(max-width:848px){
            width: 77%;
        }
        @media (max-width:610px){
            align-items: flex-start;
        }

        & h1{
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 900;
            font-size: 70px;
            line-height: 79px;
            text-align: center;
            color: #151F32;
            margin-bottom: 16px;
            @media (max-width:610px){
                font-size: 3rem;
                text-align: start;
                
            }

        }
        & p{
            font-weight: 450;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.2px;
            color: #4A5873;
            margin-bottom: 32px;
            width: 50%;
            @media (max-width:610px){
                width: 100%;
                text-align: start;
            }
        }
    }

    & .about-img{
        position: absolute;
        top:30px;
        right: 0;
        z-index: -1;
    }

}


// values


.value-items-icon{
    width:100%;
    & img{
        margin-bottom: 32px;
    }

    & .value-items-content{
        & h1{
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #151F32;
        }

        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 150.5%;
            color: #4A5873;
            margin-bottom: 16px;
        }
    }
}



.about-value{
    width: 100%;
    display: flex;
    background: #F3FBFB;
    position: relative;
    justify-content: center;
    z-index: -1;

    .aboutRibbon-img{
        position: absolute;
        right:0;
        top:415px;
        z-index: -1;
        @media (max-width:1348px){
            display: none;
        }
    }
    & .about-value-container{
        width:77%;
        margin-top: 78px;
        margin-bottom: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;

        

        & .about-value-content{
            width:60%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 64px;
            @media (max-width:1000px){
                width: 100%;
            }
            & h1{
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 46px;
                color: #151F32;
                text-align: center;
                @media (max-width:550px){
                    text-align: start;
                }
                
            }

            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 140%;
                text-align: center;
                letter-spacing: 0.2px;
                color: #4A5873;
                @media (max-width:550px){
                    text-align: start;
                    font-size: 1rem;
                }
            }
        }

        & .about-images{
            display: flex;
            width:100%;
            justify-content: space-between;
            align-items: center;
            @media (max-width:1243px){
                flex-direction: column;
                gap:2rem;
            }

            & .value-item{
                width: 53%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 54px;
               
                @media (max-width:1243px){
                    width: 100%;
                }
                
                @media (max-width:550px){
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}

// customer trust
.customer-trust-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media #{$md-layout}{
        flex-direction: column;
        align-items: center;
    }
    
}
.customer-trust-title{
    width: 44%;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #111928;
    @media #{$md-layout}{
        width: 100%;
        text-align: center;
    }
    @media #{$large-mobile}{
        font-size: 2rem;
    }
}
.customer-trust-count{
    display: flex;
    flex-direction: column; 
    width:47%;
    & .customer-trust-description{
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.2px;
        color: #4A5873;
        @media #{$md-layout}{      
            text-align: center;
        }
    }  
    & .count-flex-row{
        display:flex;
        flex-direction: row;
        justify-content: space-between;

        & .count-flex-column{
            display: flex;
            flex-direction: column;
            & h1{
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 51px;
                color: #111928;

                & span{
                    color: #2EBDB6;
                }
                @media #{$large-mobile}{
                    font-size: 1.5rem;
                }
            }
            & p{
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.2px;                
                color: #4A5873;
                @media #{$large-mobile}{
                    font-size: 0.8rem;
                }
            }
        }
        @media #{$md-layout}{
           
            text-align: center;
        }
    }
    @media #{$md-layout}{
        width: 100%;
    }
}

// main about

.about-main{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 128px;

    & .about-main-container{
        width:77%;

    }
}


// AboutMainContent item component

.main-about-items{
    width:100%;
    display: flex;
    margin-top:128px;
    align-items: center;
    justify-content: space-between;

    @media (max-width:1148px){
        flex-direction: column-reverse;
        gap:2rem;
    }

}
.main-about-item-reverse{
    width:100%;
    display: flex;
    margin-top:128px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    @media (max-width:1148px){
        flex-direction: column-reverse;
        gap:2rem;
    }

}

.main-about-items-content{
    width:41.5%;
    display: flex;
    flex-direction: column;

    @media (max-width:1148px){
        width: 100%;
    }
    & span{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 23px;
        background: #EAF8F8;
        border-radius: 20px;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 110%;
        color: #2EBDB6;
        width: fit-content;
        margin-bottom: 32px;
    }

    & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #111928;
        margin-bottom: 32px;

    }
    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.2px;
        color: #4A5873;
        margin-bottom: 32px;
    }
}

// News section

.about-news-section{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 128px;

    & .about-news-content{
        width:50%;
        margin-bottom: 64px;
        @media(max-width:1000px){
            width:100%;
        }

        & h1{
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            color: #111928;
        }

        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #4A5873;
        }
    }

    & .about-news-feed{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        overflow: hidden;
        gap:38px; 
        @media (max-width:1000px){
            grid-template-columns: 1fr 1fr ;
        }

        @media (max-width: 600px){
            grid-template-columns: 1fr;
        }
    }

}

