.scan-download{
    margin:0 auto;
    width: 100%;
    overflow: hidden;
    max-width: 1440px;

    .scan-main{
        padding-left: 60px;
        padding-right: 20px;
        margin-top: 200px;
        width: 77%;
        background-color: #EAF8F8;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        .scan-content{
            display: flex;
            width:100%;
            flex-direction: column;
            align-items: center;
            margin-top: 2rem;
            & h1{
                
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 300;
                font-size: 36px;
                line-height: 51px;
                color: #151F32;
                @media(max-width:514px){
                    font-size: 2rem;
                }
            }

            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 140%;
                /* or 25px */

                letter-spacing: 0.2px;

                color: #4A5873;
                margin-bottom: 33px;
                @media(max-width:514px){
                    font-size: 1rem;
                }
            }
        }
        .scan-flex{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.scan-left{
    // width: 400px;
    
}
.scan-right{
//  @media (max-width:1152px){
//     width: 400px;
//  }
}
