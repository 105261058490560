

.news{
    width:100%;
    max-width: 1440px;
    overflow: hidden;

}
.news-hero{
    width: 100%;
    position: relative;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    
    & .news-hero-container{
        width: 52%;
        margin-top: 128px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width:1000px){
            width: 77%;
            align-items: flex-start;
            margin-top: 4rem;
            
        }
        
        
        & h1{
            font-style: normal;
            font-weight: 900;
            font-size: 70px;
            line-height: 79px;  
            text-align: center;
            color: #151F32;   
            @media (max-width:1000px){
                text-align: start;
            }
            @media (max-width:620px){
                font-size: 2rem;
            }
            @media (max-width: 380px){
                font-size: 3rem;
            }          
        }

        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 27px;  
            text-align: center;
            letter-spacing: 0.2px;
            color: #4A5873;  
            width: 502px; 
            @media (max-width:1000px){
                text-align: start;
            }
            @media (max-width: 479px){
                width:100%;
                margin-top: 50px;
                position: relative;
                z-index: 3;
            }

            @media (max-width:479px){
                margin-top:1rem;
            } 
                     
        }
    }
    & img{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}

.news-main{
    width: 100%;
    margin-top: 128px;
    overflow: hidden;
    & .news-main-container{
        width: 77%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width:1000px){
        margin-top: 5rem;
    }
}
.news-tab{
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    width:100%;
    padding-bottom: 23px;
    @media(max-width:480px){
        justify-content: space-between;
    }
   & button {
        border:none;
        background-color: transparent;
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #151F32;
        position: relative;
        margin-right: 40px;
        @media (max-width:567px){
            font-size: 1rem;
        }
        @media (max-width:527px){
            font-size: 0.8rem;
        }
        @media (max-width: 479px){
            font-size: 0.6rem;
            margin-right: 0;
        }
        &:focus-visible {
            outline: none;
        }
        &:after {
            content: "";
            height: 2px;
            width: 100%;
            background-color: var(--color-primary);
            position: absolute;
            bottom: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            transition: var(--transition);
        }
        &.is-checked {
            color: #151F32;
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
// news feed
.news-feed{
    width: 100%;
    display: grid;
    margin-top: 38px;
    grid-template-columns: repeat(3,1fr);
    overflow: hidden;
    gap:38px; 
    @media (max-width:965px){
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 750px){
        grid-template-columns: 1fr;
    }
}
.news-feed-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    overflow: hidden;
    
    .news-img{
        margin-bottom: 21px;
    }
    .news-time{
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        
        & span{
            display: block;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 150%;
            text-transform: capitalize;
            color: #808080;
            
            &.news-time-end{
                margin-left: 100px;
            }
        }
        
    }

    .news-line{
        border: 0.78388px solid #DCDCDC;
        margin-bottom: 9px;
    }
    & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #2F2F2F;
        margin-bottom: 16px;
    }
    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 150.5%;
        color: #808080;
        margin-bottom: 13px;
    }
    & .view-more{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 145.5%;
        color: #2EBDB6 !important;

    }
}
