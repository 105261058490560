.merchant{
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
}
.merchant-hero{
    width: 100%;
    margin-top:120px;
    background: #F3FBFB;
    position: relative;
    z-index:1;
    
    @media (max-width:400px){
        height:fit-content;
    }

    & .merchant-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 2;
        
        
        .merchant-hero-container{
            width:45.7%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 576px;
            margin-top:128px;
            
            @media (max-width:1101px){
                width: 50%;
            }
            @media (max-width:1007px){
                align-items: flex-start;
                
            }
            @media (max-width:895px){
                width: 77%;
            }
            @media (max-width:700px){
                margin-top: 3rem;
            }
            & h1{
                font-style: normal;
                font-weight: 900;
                font-size: 70px;
                line-height: 79px;
                text-align: center;
                color: #151F32;
                margin-bottom: 32px;
                text-align: center;
                @media (max-width:1007px){
                    text-align: start;
                    font-size: 3rem;
                }
                @media (max-width:550px){
                    font-size: 2rem;
                    line-height: 60px;
                }
                @media (max-width:387px){
                    font-size: 1.5rem;
                    
                }
            }
    
            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 140%;
                text-align: center;
                letter-spacing: 0.2px;
                color: #4A5873;
                text-align: center;
                width: 87%;
                margin-bottom: 32px;
                @media (max-width:1007px){
                    text-align: start;
                    width: 100%;
                }
                @media (max-width:550px){
                    font-size: 1rem;
                    
                }
            }

            & .merchant-btn{
                display: flex;
                gap: 16px;
                align-items: center;
                justify-content: center;
               

                @media (max-width:493px){
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                
            }
    
        }
    }
    & .ribbon-left{
        position: absolute;
        top:325px;
        left:0px;
        z-index:1;
        @media (max-width:520px){
            display: none;
        }
    }
    & .ribbon-right{
        position:absolute;
        top:158px;
        right:0px;
        z-index: 1;
    }

    & .merchant-slider{
        
        display: flex;
        padding: 0;
        position: absolute;
        justify-content: space-between;
        top:744px;
        align-items: center;
        gap:34px;
        z-index: 2;  
        @media (max-width:1007px){
            top:600px;
        }

        @media (max-width:520px){
            top:450px;
        }
        @media (max-width:450px){
            top:500px
        }
        @media (max-width:388px){
            top:550px;
            
        }
        
     }

}

// ====== marchant benefits ======


.merchant-main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 500px;
    margin-bottom: 108px;

    @media (max-width:1283px){
        margin-top: 400px;
    }
    @media (max-width:1000px){
        margin-top: 300px;
    }
    @media (max-width:520px){
        margin-top: 200px;
    }
    @media (max-width:493px){
        margin-top: 150px;
    }
    @media (max-width:451px){
        margin-top: 155px;
    }
    @media (max-width:390px){
        margin-top: 200px;
    }
    
    & .merchant-main-container{
        width:77%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap:125px;

        // @media(max-width:451px){
        //     margin-top: ;
        // }

        & .merchant-benefit-content-wrapper{
            width:49%;
            @media (max-width:1231px){
                width: 100%;
            }
        }
    }

}

.merchant-benefit-content{
    margin-bottom: 64px;
    width: 83%;
    @media (max-width:520px){
        width: 100%;
    }
    & span{
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 110%;
        color: #2EBDB6;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 23px;
        background: #EAF8F8;
        border-radius: 20px;
        margin-bottom: 32px;
        width: fit-content;
    }
    & h1{
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #111928;
        margin-bottom: 16px;

    }

    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.2px;
        color: #4A5873;
        
    }
}
// merchant benefit

.merchant-benefit{
    width: 100%;
    
    & .merchant-benefit-container{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:1231px){
            flex-direction: column-reverse;
            gap: 2rem;
        }
    }
    & .merchant-benefit-container-left{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:1231px){
            flex-direction: column;
            gap: 2rem;
        }
    }


}

.icon-benefits{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    
    @media (max-width: 991px){
        margin-bottom: 2rem;
    }

    @media (max-width:575px){
        flex-direction: column;
        align-items: center;
    }

    & .icon-benefits-text{
        width:71%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media (max-width:575px){
            width:100%;
            align-items: center;
        }
        @media (max-width:550px){
            width:100%;
            align-items: flex-start;
        }
        & h1{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #151F32;

            @media (max-width:550px){
                font-size: 1.5rem;
            }
        }
        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 150.5%;
            color: #4A5873;
            @media (max-width:550px){
                font-size: 1rem;
            }
        }
    }
    @media (max-width:767px){
        .icon-benefits-img{
           width: 100px;
           height: 100px;
        }
    }
    @media (max-width:575px){
        .icon-benefits-img{
           display: none; 
        }
    }
}

.merchant-join{
    width:77%;
    margin-left: auto;
    margin-right: auto;
}

