.faqs-terms{
    margin: 0 auto;
}
.terms-hero{
    width:100%;
    background: #F3FBFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 120px;

    & .terms-hero-container{
        width:50.6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 116px;
        margin-bottom: 116px;
        & span{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 6px 10px;
            background: #D5F2F0;
            border-radius: 100px;
            width: fit-content;
            margin-bottom: 22px;
        }
        & h1{
            font-style: normal;
            font-weight: 900;
            font-size: 70px;
            line-height: 79px;
            text-align: center;
            color: #151F32;
            margin-bottom: 16px;
        }

        & p{
            width:63.9%;
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #4A5873;
        }

    }

    & img{
        position:absolute;
        bottom: 0;
        right: 0;
    }
}

.terms-condition-main{
    width: 77%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    position: relative;
    margin-top: 59px;
   
    
    & .terms-condition-main-content{
        width:73%;
        margin-left: 32px;
    }

  & .terms-condition-nav{
    width: 22%;
    position: sticky;
    top:100px;
  
  }
}


// sideMenu links
.terms-sidemenu-link-container{
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // position: fixed;
    // top:0;
    // left: 0;
    gap: 4px;
    filter: drop-shadow(9px 21px 30px rgba(21, 31, 50, 0.04));
   
}
.terms-submenu{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 14px;
    background: #FFFFFF;
    border: 1px solid rgba(154, 174, 213, 0.2);
    border-radius: 6px;
    width: 100%;

    & .terms-sidemenu-link{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
     
        & h6{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #151F32;
        }
    }
    & .submenu-text{
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 20px;
        margin-left: 20px;
        & a{
            color: #4A5873;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;

            &:hover{
                color: var(--color-link);
            }
        }
    }
}

.faq-introduction{
    .faq-title{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #151F32;
        margin-bottom: 36px;

        
    }
    .faq-desc{
        & h3{
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #111928;
            // margin-bottom: 16px;
            
        }

        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.2px;
            color: #4A5873;
            margin-bottom: 44px;
            
        }
    }
}
.terms-link-color{
    color: #259792;
}

.terms-sec{
    & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #151F32;
        margin-bottom: 32px;
    }
    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 25px;
        color: #4A5873;
        margin-bottom: 35px;
    }
}
.def-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #151F32;
}
.def-text{
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 25px;
    color: #4A5873;
    margin-bottom: 35px;
    display: inline;
    margin-left: 10px;
}
.terms-sec .policy-mb{
    margin-bottom: 5px;
}
.terms-sec li{
    margin-bottom: 5px;
}
 ol{
    list-style-type: lower-roman;
 }