.landing-hero{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    
    .landing-wrapper{
        width: 88%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width:880px){
            flex-direction: column;
        }
        .hero-left{
           
            width: 50%;
           
            @media (max-width:1126px){
                width: 60%;
            }
            @media (max-width:880px){
                width: 100%;
               
            }
            .hero-left-top{
                border-radius: 100px;
                background: #F6EFEF;

                padding: 10px;
                color: #5A5A5A;
                text-align: center;
                font-family: "DM Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.28px;
                margin-bottom: 32px;
                width: fit-content;
                @media (max-width:760px){
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .hero-left-text{
                color: var(--Flick-Secondary-5, #151F32);
                font-family: "Schibsted Grotesk";
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.96px;

                &.mobile_text_h{
                    display: none;

                    @media (max-width:760px){
                        display: block;
                        font-size: 48px;
                        
                    }
                }
                @media (max-width:1126px){
                    font-size: 30px;
                }
                @media (max-width:760px){
                    align-items: center;
                    display: none;
                }

                &  span{
                    color: var(--Flick-Primary-6, #259792);
                    font-family: "Schibsted Grotesk";
                    font-size: 48px;
                    font-style: italic;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.96px;
                }
            }
            .desc-landing{
                color: var(--Flick-Subtext, #8C8F97);
                font-family: "DM Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 133.333% */ /* 187.5% */
                margin-top: 24px;
                
            }
            .hero-btn{
                display: flex;
                align-items: center;
                margin-top: 32px;
                border-radius: var(--radius-full, 9999px);
                background: #259792;
                padding: 6px 16px;
                width: fit-content;
                @media (max-width:760px){
                    margin-left: auto;
                    margin-right: auto;

                }
                @media (max-width:580px){
                    gap: 0;
                    width: fit-content;

                }
                .arrow-btn{
                    display: inline-block;
                    &:hover{
                      transform: scale(1.25);  
                    }
                }
                .landing-download-btn{
                  &:hover{
                    transform: scale(1.04);
                    cursor: pointer;
                  }    
                }
            }
        }
        .hero_right{
        //    position: relative;
        @media (max-width:880px){
            margin-top: 3rem;
        }
        @media (max-width:760px){
            display: none;
        }
           
           .hero_middle{
            // position: absolute;
           /* Move the left side of the element to the middle of the container */
            /* Move the left side of the element to the middle of the container */
            // top:200px;
            // right: 200px;
           }
        }
        
    }
}

// partners
.partners{
    width: 100%;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .partner-wrapper{
        width: 52%;
        border-radius: 16px;
        border: 1px solid var(--Flick-Stroke, #EAECF0);
        display: flex;
        align-items: center;
        justify-content: center;

        .partner-inner{
            width: 91.5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 32px;
            margin-bottom: 32px;
            .partner-title{
                color: #8C8F97;
                text-align: center;     
                font-size: 14px;
                font-style: normal;
                font-weight: 450;
                line-height: 30px;
            }
            .partner-slider{
                display: flex;
                align-items: center;
                gap: 64px;
                margin-top: 16px;
                margin-bottom: 28px;
            }

            .partner-ruler{
                background: #EAECF0;
                width: 100%;
                border: 1px solid #EAECF0;
            }
            .partner-license{
                display: flex;
                align-items: center;
                gap: 16px;
                margin-top: 34px;
            }
        }
    }
}

// main

.landing_main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    

    .main_wrapper{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      .flick-product-content{
        margin-top: 100px;
        display: flex;
        width: 100%;
       
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width:580px){
           
            margin-top: 20px;
        }
        .flick-product{
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           
           @media (max-width:580px){
             width: 100%;
            }
            .flick-product-p{
                margin: 0;
                padding: 0;
                color:  #151F32;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
                letter-spacing: -0.28px;
                border-radius: 100px;
                background: #F6EFEF;
                width: fit-content;
                padding: 10px;
                margin-bottom: 26px;
            }
            .flick-product-h{
                margin: 0;
                padding: 0;
                color: #000;
                text-align: center;
                font-family: "Schibsted Grotesk";
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px; /* 127.778% */
                & span{
                    color: var(--Flick-Primary-6, #259792);
                    font-family: "Schibsted Grotesk";
                    font-size: 36px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: 46px;
                }

                @media (max-width:400px){
                    font-size: 25px;
                }
              
            }

            .flick-product-btn{
                display: flex;
                border-radius: 60px;
                background: #F2F5F7;
                align-items: center;
                margin-top: 32px;
                padding: 7px;
                @media (max-width:580px){
                    margin-bottom: 2rem;
                }
                .flick-product-btn1{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    padding: 10px 14px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 116px;
                    transition: background-color 0.3s ease, color 0.3s ease;
                    cursor: pointer;
                    color: var(--Subtext-2, #606B81);
                    text-align: center;
                    font-family: "DM Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &.active{
                        border-radius: 60px;
                        background: var(--flick-primary-6, #259792);
                        display: flex;
                        width: 116px;
                        padding: 10px 14px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        color: #FFF;
                    }
                }
                
            }
        }
       
      }  

      .landing-getstarted{
        width: 100%;
        margin-top: 140px;
        // background: #F1FAFA;

        @media(max-width:760px){
            display: none;
        }
        @media(max-width:580px){
            margin-top: 3rem;
        }

        .getstarted-content{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 64px;

            @media(max-width:580px){
                flex-direction: column;
                
            }
            .getstarted-content-h{
                color: var(--flick-secondary-5, #151F32);
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 50px; 
                text-align: center;
                @media(max-width:580px){
                    width: 100%; 
                        
                }
            }
            .getstarted-content-p{
                color: var(--Flick-Subtext, #8C8F97);
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: 25px; 
                width: 33%;
                @media(max-width:580px){
                    width: 100%;     
                }
            }
        }
        
      }
    }

    .data-tools{
        width: 100%;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 128px;
        
        @media(max-width:580px){
            margin-top: 3rem;     
        }
        @media(max-width:580px){
            margin-top: 3rem;     
        }
        .data-tools-wrapper{
            width: 88%;
        

            // @media(max-width:800px){
            //     margin-top: 3rem;     
            // }
            // @media(max-width:580px){
            //     margin-top: 3rem;     
            // }
            .data-tool-content{
                width: 55%;
                margin: 0 auto;
                margin-bottom: 64px;
                @media (max-width:700px){
                    width: 100%;
                }
                .usecase{
                    color: var(--Flick-Secondary-5, #151F32);
                    text-align: center;
                    font-family: "DM Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.28px;
                    border-radius: 100px;
            
                    background: #F6EFEF;
                    padding: 10px;
                    // margin-bottom: 42px;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                }
                .finance-title{
                    color: #000;
                    text-align: center;
                    font-family: "Schibsted Grotesk";
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    @media (max-width:800px){
                        width: 100%;
                    }
                }
                .finance-desc{
                    color: var(--Flick-Subtext, #8C8F97);
                    text-align: center;
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 156.25% */
                }
            }

            .data-tool-grid{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 21.33px;
                padding-bottom: 92px;
                @media(max-width:1000px){
                   grid-template-columns: 1fr 1fr;  
                   gap: 1.5rem;   
                }
                @media(max-width:760px){
                   grid-template-columns: 1fr;  
                   gap: 21.33px;   
                }

               
            }
        }
    }

    .api-sdk{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .api-sdk-wrapper{
            width: 88%;
            margin-top: 128px;
            @media(max-width:580px){
                margin-top: 3rem;     
            }
            .api-sdk-img{
               
                display: flex;
                gap: 118px;
                align-items: center;
                justify-content: space-between;
                @media(max-width:760px){
                    flex-direction: column;     
                }
               
                .api-sdk-right{
                    display: flex;
                    flex-direction: column;
                    
                    // gap: 60px;
                    width: 35%;
                    @media(max-width:760px){
                       width: 100%;    
                    }
                    .api-sdk-content{
                        display: flex;
                        justify-content: space-between;     
                        margin-bottom: 64px;
                        @media(max-width:580px){
                            margin-top: 2rem;     
                        }
        
                        .api-sdk-title{
                            color: var(--Flick-Secondary-5, #151F32);
                            font-family: "Schibsted Grotesk";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 55px; /* 137.5% */
                        }
                        // .api-sdk-btn{
                        //     display: flex;
                        //     align-items: center;
                        //     gap: 12px;
        
                        //     .api-btn{
                        //         border-radius: 0px 6px 6px 0px;
                        //         background: #F2F5F7;
                        //         color: #666;
                        //         display: flex;
                        //         width: 120px;
                        //         height: 48px;
                        //         padding: 0px 12px;
                        //         justify-content: center;
                        //         align-items: center;
        
                        //         &.api-active{
                        //             color: #FFF;
                        //             border-radius: 6px 0px 0px 6px;
                        //             background: var(--flick-primary-6, #259792);
                        //         }
                        //     }
                        // }
                    }
                    .sdk_text{
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
                        .sdk-labels{
                            
                            .sdk-headings{
                                color: var(--Flick-Secondary-5, #151F32);
                                font-family: "Schibsted Grotesk";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.069px; /* 175.428% */
                            }
                            .sdk-paragraphs{
                                color: var(--Flick-Subtext, #8C8F97);
                                font-family: "DM Sans";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px; /* 150% */
                            }
                        }
                    }
                    .explore-btn{
                        border-radius: 6px;
                        border: 1px solid #999;
                        display: flex;
                        width: 191px;
                        outline: none;
                        background-color: transparent;
                        padding: 14px 12px;
                        justify-content: center;
                        align-items: center;
                        color: var(--flick-secondary-5, #151F32);
                       
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        
                        &:hover{
                            background-color: #F4F4F5;
                        }
                    }
                }
            }
            

           
        }
    }

    .why-flick{
        width: 100%;
        margin: 0 auto;
        display: flex;
        margin-top: 100px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        @media(max-width:580px){
            margin-top: 3rem;     
        }

        .banks_why{
           height: 400px;
           width: 100%;
           position: absolute; 
           background: transparent;
           border: none;
           top: 0;
           z-index: 5;
           
           @media(max-width:580px){
            display: none;     
            }
           .gtb{
            position: absolute;
            left: 84px;
            top: 49;
           }
           .uba{
            position: absolute;
            left: 31px;
            bottom: 33px;
           }
           .kuda{
            position: absolute;
            left: 361px;
            top: 138px;
           }
           .access{
            position: absolute;
            left: 571px;
            top: 332px;
           }
           .fcmb{
            position: absolute;
            right: 503px;
            top: 393px;
           }
           .circle{
            position: absolute;
            right: 409px;
            top: 99px;
           }
           .unknown{
            position: absolute;
            right: 283px;
            top: 198px;
           }
           .unknown2{
            position: absolute;
            right: 0;
            top: 99px;
           }
           .firstBank{
            position: absolute;
            right: 57px;
            top: 331px;
           }

        }
        .why-wrapper{
            width: 88%;
      
            .why-content{
                width: 44%;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                
                .why-subtitle{
                    margin: 0 auto;
                    display: flex;
                    width: 120px;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    background: #F6EFEF;
                    color: var(--flick-secondary-5, #151F32);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                    letter-spacing: -0.28px;
                }
                .why-title{
                    color: var(--Flick-Secondary-5, #151F32);
                    font-family: "Schibsted Grotesk";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            .why-img{
                display: flex;
                // justify-content: space-between;
                align-items: flex-start;
                gap: 174px;
                position: relative;
                @media(max-width:1000px){
                    flex-direction: column-reverse;
                    // position: relative;    
                }
                
               
                .why-right{
                    display: flex;
                    flex-direction: column;
                    width: 34%;  
                    margin-top: 180px;  
                    @media(max-width:1000px){
                        width: 100%;
                        margin-top: 0;
                        //  top: 600px;
                       
                        //  position: absolute; 

                         
                        

                    }
                    @media(max-width:580px){
                        width: 100%;
                      
                         margin-top: 0;
                       
                        

                    }
                    
                }
            }
        }
        
    }
}

.flick-product-items{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 64px;
    gap: 50px;  
    overflow: hidden;

    @media (max-width:1000px){
        grid-template-columns: 1fr 1fr;
       
        
    }

    @media (max-width:760px){
        grid-template-columns: 1fr;
        
    }
   
    &.selected{
       
    }
    .flick-product-item{
        display: flex;
        gap: 24px;
        align-items: flex-start;

        .flick-product-item-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            .product-checkout{
                color: var(--Flick-Secondary-5, #151F32);
                font-family: "Schibsted Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                
            }

            .checkout-desc{
                color: var(--Flick-Subtext, #8C8F97);
                font-family: "DM Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.1px;
            }
        }
    }
    
}

// 

.data-tool-item{
    border-radius: 26.667px;
    border: 1px solid #EBEBEB;
    background: #FBFBFB;
    box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
    position: relative;
    padding: 24px;
    
    & .tag_tool{
        position: absolute;
        right: 24px;
        top: 24px;
        border-radius: var(--radius-full, 9999px);
        display: flex;
        padding: 4px 10px;

        justify-content: center;
        align-items: center;

        & .tag_text{
            color: #000;
            font-family: "DM Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
        }

    }
    & img{
        margin-bottom: 24px;
    }
    .data-subtitle{
        color:  #8C8F97;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .data-title{
        color: var(--Gray-900, #101828);
        font-family: "Schibsted Grotesk";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 125% */
        letter-spacing: -0.48px;
    }
    .data-desc{
        color: var(--Flick-Subtext, #8C8F97);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    
}

// why flick

.why-container{
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 33px;
    .why-item-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .why_num{
           margin: 0;
           display: flex;
           align-items: center;
           justify-content: center;
           color: var(--flick-secondary-5, #151F32);
           text-align: center;
           font-family: Circular Std;
           font-size: 16px;
           font-style: normal;
           font-weight: 450;
           line-height: normal;
           background: #EAECF0;
           width: 36px;
           height: 36px;
           border-radius: 50%;

           &.active{
            background: #259792;
            color: #FFF;
           }
        }
        & span{
            
            border: 1px solid #EAECF0;
            height: 150px;
        }
    }
    .why-item-content{
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     .why-item{

        .why-title{
            color: var(--flick-secondary-5, #151F32);
            font-family: Circular Std;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .why-desc{
            color: var(--Flick-Subtext, #8C8F97);
            font-family: "DM Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 178.571% */
            letter-spacing: 0.1px;
        }
     }
    }
}


// start building

.start_building{
    background: url('../../assets/landingBannerBg.svg')no-repeat center center/cover;
    position: relative;
    border-radius: 20px;
    width: 88%;
    margin-top: 150px;
    padding-top: 25px;
    overflow: hidden;
    @media(max-width:1000px){
        margin-top: 0;     
    }
    @media(max-width:760px){
        margin-top: 50px;     
    }
    .land_avater{
        position: absolute;
        bottom: 0;
        right: 0;
        @media(max-width:580px){
            right: 2%; 
            top: 20%;  
        }
    }
    .avater_content{
        margin-top: 74px;
        margin-bottom: 105px;
        margin-left: 82px;
        width: 36%;
        @media(max-width:580px){
            margin-top: 3rem;    
            position: relative;
            z-index: 2; 
            margin-left: 2rem;
            width: 80% ;
        }

        .avater_desc{
            color: rgba(247, 253, 252, 0.90);
            font-family: "DM Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.5%; /* 28.5px */
            margin-top: 18px;
        }
        .avater_title{
            color: #FFF;
            font-family: "Schibsted Grotesk";
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 52.8px */
            & span{
                color: #FFF;
                font-family: "Schibsted Grotesk";
                font-size: 48px;
                font-style: italic;
                font-weight: 500;
                line-height: 110%;
            }
        }
        .avater_btn{
            border-radius: 9999px;
            border: 1px solid var(--Flick-Primary-4, #58CAC5);
            background: #259792;
            color: #FFF;
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            margin-top: 48px;
            display: flex;
            padding: var(--spacing-none, 16.5px) var(--spacing-none, 33px) var(--spacing-none, 17.5px) var(--spacing-none, 33px);
            justify-content: center;
            align-items: center;
            width: fit-content;
            cursor: pointer;
        }
    }
}
// .data-tool-item:last-child {
//     grid-column: span 2; /* This will make the last item take 2 columns */
// }

.land_img_why{
    @media (max-width:760px){
        display: none;
    }
}

.web_banner{
    @media(max-width: 760px){
        display: none;
    }
}

.mobile_banner{
    display: none;

    @media (max-width:760px){
        display: block;
    }
}
.api_code_mobile{
    @media (max-width:760px){
        display: none;
    }
}
