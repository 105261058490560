.career{
    width:100%;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
}
.career-main{
    width: 100%;
    overflow: hidden;

}
.hero-career{
    margin-top: 200px;
    width:77%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    
    @media (max-width:1154px){
        flex-direction: column;
        gap:3rem;

    }
    // @media (max-width: 479px){
    //     flex-direction: column;
    //     gap:40px;
    //     align-items: center;
    //     margin-top: 150px;
    // }

    
    .hero-career-left{
        width: 52%;
        
        @media (max-width:1154px){
            width:100%;
        }
       
        & .hiring{
            align-items: center;
            padding: 10px 23px;
            width: 133px;
            background: #EAF8F8;
            border-radius: 20px;
            margin-bottom: 16px;
        }
        & h1{
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 700;
            font-size: 70px;
            line-height: 79px;
            color: #151F32;
            margin-bottom: 32px;
            @media (max-width:716px){
                font-size: 3rem;
            }
            @media (max-width: 500px){    
                font-size: 2rem;
                line-height: 50px;
            }
            
            & span{
                display: inline-block;
                background: url(../../assets/careerWordBg.svg) no-repeat center center/cover;
                color: white;
                padding: 0px 5px 5px 0px;
                @media (max-width:716px){
                    background-size: 200px;
                }

                @media (max-width: 500px){    
                    padding: 2px 0px 2px 2px;
                    width: 140px;
                }
            }
        }
        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.2px;
            color: #7283A4;
            margin-bottom: 64px;
            @media (max-width: 479px){
                margin-bottom: 2rem;
            }

        }

    }

    @media (max-width:1154px){
        .hero-career-right{
            display: flex;
            align-self: center;
        }
    }
}
.career-value{
    width: 100%;
    margin-top: 128px;
    background: #F3FBFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 479px){
        margin-top: 100px;
    }
    
}
.career-value-container{
    width:77% ;
    margin-top: 145px;
    margin-bottom: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    @media (max-width: 479px){
        margin-top: 50px;
        margin-bottom: 50px;
    } 
}
.career-value-content{
    width: 46%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
    
    @media (max-width: 479px){
        width: 100%;
        
    }
    & h1{
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 46px;
        color: #151F32;
    }

    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.2px;
        color: #4A5873;
    }
}

.career-testimonial{
    margin-top: 125px;
    width: 100%;
    position: relative;
    & .testimonialbg{
        position: absolute;
        right:0px;
        top:150px;
        z-index: -1;
        @media (max-width:1176px){
            display: none;
        }
    }
    & .career-testimonial-container{
        
        width: 77%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 3;

        // @media (max-width:1176px){
        //     display: none;
        // }
        @media (max-width: 1096px){
            flex-direction: column; 
            gap: 40px;      
        }
    }
    @media (max-width: 479px){
        flex-direction: column; 
        gap: 40px;      
    }
    & .career-testimonial-content{
        width:45%;
        
        @media (max-width: 1096px){
            width: 100%;
            
        }
        
        & h5{
            margin-top: 32px;
            margin-bottom: 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #111928;

        }
    }

}

.position-main{
    width:100%;
    background: #F3FBFB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 116px;
    padding-top: 106px;
    padding-bottom: 100px;
    @media (max-width: 479px){
        margin-bottom: 0px;   
        padding-bottom: 40px;   
    }

    & .position-container{
        width:77%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .position-title{
            width: 53%;
            margin-bottom: 64px;
            @media (max-width: 479px){
                width: 100%;         
            }
            & h1{
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                color: #111928;
                margin-bottom: 18px;
            }

            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #4A5873;
            }
        }

        & .position-item{
            width:100%;
            display: flex;
            flex-direction: column;
           
        }

    }
}

.position{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 118px;
    align-items: center;
    background: #EAF8F8;
    border-radius: 6px;
    padding-left: 38px ;
    padding-right:38px;
    margin-bottom: 20px;
    @media (max-width:768px){
        flex-direction: column;
    }
    @media (max-width: 768px){
        flex-direction: column;
        padding-left: 20px ;
        padding-top: 20px;
        overflow: hidden;
        height: fit-content;
        padding-bottom: 20px;
    }
    & .position-left{
        width:30%;
        display: flex;
        flex-direction: column;
        @media (max-width:980px){
            width: 50%;
        }
        @media (max-width: 768px){
            width: 100%;          
        }
        & h1{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: #2F2F2F;
            margin-bottom: 9px;
        }
        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: 0.2px;
            color: #4A5873;
        }
    }
    & .position-right{
        
        & button{
            border: none;
            
            align-items: center;
            padding: 15px 23px;
            box-sizing: border-box;
            border-radius: 50px;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 110%;
           
        }
    }
}

.other-role{
    
    border:none;
    background-color: transparent;
    font-weight: 450;
    font-size: 16px;
    line-height: 110%;
    color: #2EBDB6;
    text-align: start;
    margin-top: 2rem;

    &:hover{
        font-size: 1.2rem;
    }
}

// career value

.value{
    width: 100%;
    display: flex;
    flex-direction: column;
    

    & img{
        margin-bottom: 32px;

    }
    & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: #151F32;
        margin-bottom: 16px;

    }
    & p{
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 150.5%;
        text-align: center;
        color: #4A5873;
    }
}

// arrow nav

.swiper-nav-btn{
    display: flex;
    gap:22px;
    justify-content: flex-start;
    margin-top: 1.5rem;
    
    width: 77%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width:1176px){
       justify-content: center;
       align-items: center;

    }
    @media (max-width:804px){
        margin-top: 0px;

    }
    
    & button{
        background: none;
        border: none;
    }

}

// mobile testimonial

.slide-content{
    
    width:77% ;
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    @media (max-width:1176px){
        display: block;
    }
   
        
    .card{
        width: 320px;
        border-radius: 25px;
        background-color: #FFF;
        margin-left: auto;
        margin-right: auto;
        .image-content, .card-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding:10px 14px;
        }

        .image-content{
            position: relative;
            row-gap: 5px;
            padding:25px 0;

            .overlay{
                position: absolute;
                left:0;
                top:0;
                height: 100%;
                width:100%;
                background-color:  var(--color-card);
                border-radius:25px 25px 0 25px;


                &::before, &::after{
                    content: "";
                    position:absolute;
                    right:0;
                    bottom:-40px;
                    height: 40px;
                    width:40px;
                    background-color:  var(--color-card);
                }

                &::after{
                    border-radius:0 25px 0 0;
                    background-color: #FFF;
                }
            }

            .card-image{
                position: relative;
                height: 150px;
                width:150px;
                border-radius:50%;
                background:#FFF;
                padding:3px
            }
            .card-image, .card-img{
                height: 100%;
                width:100%;
                object-fit: cover;
                border-radius: 50%;
                border: 4px solid  var(--color-card);
            }
        }

        .card-content{
            .name{
                font-size: 18px;
                font-weight: 500;
                color: #333;

            }
            .description{
                font-size: 14px;
                color:#707070;
                text-align: center;
            }
        }
    }
    
}