@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap');
/*-------------------------
    Variables
-------------------------*/
:root {
  
  
  --color-primary: #2EBDB6;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #ECF2F6;
  --color-dark: #151F32;
  --color-dark-link: #7f8082;
  --color-text-dark: #292930;
  --color-accent1: #FFDC60;
  --color-accent2: #FAB8C4;
  --color-card:#F3FBFB;
  --color-blue-shade: #6865FF;
  --color-link: #7f8082;
  --color-mabel: #DBF8FF;
  --color-fog: #DBDEFF;
  --color-pink-shade: #FFD3DB;
  --color-peach: #FFEDDC;
  --color-rose: #C75C6F;
  
  --color-chart1: #896BA7;
  --color-chart2: #BADEFF;
  
  --color-body: #525260;
  --color-gray-1: #757589;
  --color-gray-2: #999FAE;
  --color-gray-3: #999AB8;
  --color-gray-4: #99A1AA;
  --color-ship-gray: #42424A;
  --color-ghost: #C7C7D5;
  --color-mercury: #E5E5E5;
  
  
  
  
  
  --gradient-iphone: linear-gradient(108.97deg,#d9e9ba 1.84%, #a98dcfe3 80.67%);
 
  --border-light: 1px solid #E3E6E9;
  --border-lighter: 1px solid #ECF2F6;
  --border-dark: 1px solid var(--color-ship-gray);
  --border-gray: 1px solid var(--color-gray-4);
  
  

  
 
  --transition: all 0.3s ease-in-out;
  



  }
  .bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}


.color-gray-4 {
    color: var(--color-gray-4);
}


.opacity-50 {
    opacity: 0.5;
}
  

  
a {
    display: inline-block;
    text-decoration: none;
    transition: var(--transition);
}
span {
    display: inline-block;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: block;
}

ul.list-style, 
ol.list-style {
    margin: -10px 0;
    
}
ul.list-style, 
ol.list-style li {
  margin: 10px 0;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}  

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  /* padding-left: 50px;
  padding-right: 50px; */
  width: 77%;
  margin-left: auto;
  margin-right: auto;
  
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container {
      max-width: 100%;
      /* padding-left: 30px;
      padding-right: 30px; */
  }
}

.landing_container{
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}



body {
  margin: 0;
  font-family: "Schibsted Grotesk", "DM Sans", sans-serif;                                      
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  
}




/*-------------------------
    Button
-------------------------*/
.btn-landing{
  width: fit-content;
  padding: 10px 12px;
  border-radius: var(--radius-full, 9999px);
  background: var(--Flick-Primary-6, #259792);
  font-style: normal;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  
  color: var(--color-white);
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.btn-landing::after{
  content: "";
  height: 300px;
  width: 300px;
  
  background-color: var(--color-primary-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}

.btn-landing:hover{
  background-color: #23847f;
  border-color: #0d5653;
  color:var(--color-light) ;
  transform: scale(1.2);
}

.axil-btn {
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 20px;
  padding: 14px 40px;
  height: auto;
  text-align: center;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  color: #F7F8FA;

}
.btn-no-fill{
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 20px;
  height: auto;
  text-align: center;
  padding: 14px 23px;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  border: 1px solid #3E404A;  
  color:#161925;

}

.axil-btn.btn-borderd {
  border: var(--border-gray);
  color: var(--color-dark);
  background-color: transparent;
 
  
}

.btn-borderd:hover {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
}


.axil-btn.btn-fill-primary {
  background-color: var(--color-black);
  color: #F7F8FA;
  overflow: hidden;
  
 
}

.btn-fill-primary::after {
  content: "";
  height: 300px;
  width: 300px;
  background-color: var(--color-dark-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  z-index: -1;
}
.btn-fill-primary:hover {
  background-color: var(--color-link);
  border-color: var(--color-link);
  
}
.btn-fill-primary:hover::after {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.axil-btn.btn-fill-white {
  background-color: var(--color-white);
  box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
  
}
.btn-fill-white:hover {
  background-color: var(--color-accent1);
  color: var(--color-white);
}

.axil-btn.btn-large {
  padding: 23px 55px;
  
}
@media only screen and (max-width: 991px) {
  .axil-btn.btn-large{
    padding: 18px 35px;
  }
}
.axil-btn.btn-large-fluid {
  padding: 23px;
  width: 100%;
}
.axil-btn.btn-fluid {
  width: 100%;
}
