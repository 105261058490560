/*-------------------------
    Forms Styles
-------------------------*/

$small-mobile: 'only screen and (max-width: 479px)'; 

input,
.form-control {
    border: var(--border-lighter);
    border-radius: 16px;
    background-color: transparent;
    color: var(--color-text-dark);
    padding: 15px 20px;
    width: 100%;
    &:focus {
        outline: none;
        box-shadow: 0 20px 48px -14px rgba(153,161,170,0.25);
        border-color: transparent;

    }
    /* -- Placeholder -- */
    &::placeholder {
        color: var(--color-gray-2);
        /* Firefox */
        opacity: 1;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--color-gray-2);
    }
    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--color-gray-2);
    }
}
input {
    height: 60px;
}

.form-group {
    margin-bottom: 16px;
    label {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-dark);
        margin-bottom: 10px;
    }
}

.form-group1 {
    margin-bottom: 18px;
    
    & .lblHeading {
        font-weight: 500;
        font-size: 1rem;
        line-height: 120%;
        color: #2F2F2F;
        margin-bottom: 6px;
    }
    & .lblEg{
        font-weight: 450;
        font-size: 0.8rem;
        line-height: 27px;
        letter-spacing: 0.2px;
        color: #4A5873;     
    }

    & input{
        height: 20px;
    }    
}


// Input Group

.input-group {
    background-color: var(--color-white);
    border-radius: 20px;
    height: 72px;
    padding: 10px 10px 10px 55px;
    box-shadow: 0 24px 48px -15px rgba(153,161,170, .25);
    @media #{$small-mobile} {
        display: block;
        box-shadow: none;
        height: auto;
        padding: 0;
    }
    .mail-icon {
        position: absolute;
        left: 30px;
        top: 22px;
        z-index: 1;
        @media #{$small-mobile} {
           left: 15px;
           top: 16px;
        }
    }
    .form-control {
        padding: 5px 15px;
        height: auto;
        border: none;
        background-color: transparent;
        &:focus {
            box-shadow: none;
        }
        @media #{$small-mobile} {
            height: 60px;
            width: 100%;
            border-radius: 20px !important;
            padding: 5px 15px 5px 50px;
            border: 1px solid var(--color-light);
        }
    }
    .subscribe-btn {
        border: none;
        background-color: var(--color-primary);
        color: var(--color-white);
        border-radius: 16px !important;
        margin-left: 0 !important;
        padding: 10px 28px;
        transition: var(--transition);
        &:hover {
            background-color: var(--color-link);
        }
        @media #{$small-mobile} {
            margin-top: 15px;
            padding: 14px 30px;
        }
    }
}

// Form Action Message
.modalform, .overlay{
    width:100vw;
    height: 100vh;
    top:0;
    left:0;
    right:0;
    bottom:0;
    position: fixed;
    z-index: 1000;
}

.overlay{
    background: rgba(49,49,49,0.8);
}


.form-position-container{
    width: 37.5%;
    max-width: 541px;
    min-width: 360px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 30px;
    position: absolute;
    top:55%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
   
}

.form-role-container{
    width: 100%;
    max-width: 541px;
    min-width: 360px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 30px;
    margin: 0 auto;
}


.position-form-title{
    margin-bottom: 62px;
    margin-top: 51px;
    & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 120%;
        color: #2F2F2F;
    }
}
.upload-cv-container{
    margin-bottom: 20px;
}
.lbl-cv{
    display: flex;
    gap:3px;
    cursor: pointer;
}

.btnsumbit-container{
    display:flex;
    text-align: left;
    gap: 10px;

    @media (max-width:971px){
        flex-direction: column;
        align-items: center;
    }
    
}

.error-msg, .success-msg {
    margin-top: 25px;
    p {
        margin-bottom: 0;
        font-size: 14px;
    }
}

.error-msg {
    color: #ff0000;
}

.success-msg {
    color: #15c54b;
    margin-bottom: 0;
}

.btn-subumit{
    border: none;
    width: fit-content;
   
}
.form-title{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
    color: #111928;

}
.form-row{
    display: flex;
    gap:100px
}
// form main
.form-section{
    margin-top: 150px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    

    & .contact-form-box{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 3rem;
        border-radius: 10px;
    }
}
.form-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-fill-black {
    background-color: #D0D5DD;
    color: #161925;
    overflow: hidden;
    
   
  }
  
  .btn-fill-black::after {
    content: "";
    height: 300px;
    width: 300px;
    background-color: var(--color-dark-link);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    z-index: -1;
  }
  .btn-fill-black:hover {
    background-color: var(--color-link);
    border-color: var(--color-link);
    
  }
  .btn-fill-primary:hover::after {
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  
  






